import $ from "jquery";
import blocksToHtml from "@sanity/block-content-to-html";


const PROJECT_ID = "li51plnt";
const DATASET = "production";
const h = blocksToHtml.h;
const SERIALIZERS = {
    types: {
        code: props => (
            h('pre', { className: props.node.language },
                h('code', props.node.code)
            )
        )
    }
};

(function() {

    // MENU SELECTOR
    window.addEventListener("scroll", onScroll);

    buildSectionIntro();
    buildSectionQui();
    buildItemGrid();
    // selectCat($("#spectacle"));

    // FILTERS
    $(".filters ul li").on("click", (e) => {
        selectCat(e.target);
    });

    function onScroll(e) {
        // Navbar show/hide
        if (
            document.body.scrollTop > 350 ||
            document.documentElement.scrollTop > 350
        ) {
            if (!$("ul.nav-menu").hasClass("visible"))
                $("ul.nav-menu").addClass("visible");
            $(".container").css("padding-right", "80px");
        } else {
            if ($("ul.nav-menu").hasClass("visible"))
                $("ul.nav-menu").removeClass("visible");
            $(".container").css("padding-right", "15px");
        }

        // Selector movement
        let lastSection = null;
        $("section h1").each(function() {
            if (
                document.body.scrollTop > $(this).parent().offset().top ||
                document.documentElement.scrollTop > $(this).parent().offset().top
            ) {
                lastSection = $(this);
            }
        });
        if (lastSection != null) {
            updateMenuSelector(lastSection);
        }
    }

    // RESIZE
    $(window).on("resize", function() {
        // TODO RESIZE UPDATE
    });

    window.setTimeout(offsetAnchor, 0);

    // Contact form handler
    $('.contact-form').on("submit", function() {
        var $this = $(this),
            result = true;

        if (!checkEmpty($this.find('#fname'))) {
            result = false;
        }
        if (!validateEmail($this.find('#email'))) {
            result = false;
        }
        if (!checkEmpty($this.find('#mssg'))) {
            result = false;
        }

        if (result == false) {
            return false;
        }

        var data = $this.serialize();

        $.ajax({
            url: "mail/sender.php",
            type: "POST",
            data: data,
            cache: false,
            success: function(html) {
                $('#result-message').addClass('alert alert-success').html('<button type="button" class="close" data-dismiss="alert" aria-label="Fermer"><span aria-hidden="true">&times;</span></button><strong>Message envoyé avec succès!</strong> Nous allons vous répondre dans les plus brefs délais.').delay(500).slideDown(500).delay(10000).slideUp('slow');
                $("#send").disabled = true;
                $$("#send").attr('disabled', 'disabled');
            },
            error: function(a, b) {
                if (b == 'error') {
                    $('#result-message').addClass('alert alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-label="Fermer"><span aria-hidden="true">&times;</span></button><strong>Désolé !</strong> Votre message n\'a pas pu être transmis...').delay(500).slideDown(500).delay(10000).slideUp('slow');
                };
            },
            complete: function() {

            },
        });
        $("#send").data("loading-text", "Message envoyé !");

        return false;
    });
})(); // End on doc ready

function buildItemGrid() {
    const QUERY = encodeURIComponent(`
        *[_type == "spectacle" || _type == "sonore" ||_type == "ecriture" ||_type == "atelier" ||_type =="divers"  ]{
            _id,
            _type,
            title,
            order,
            excerpt,
            body,
            "imageUrl1": image1.asset->url
          }  | order(order asc)
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        let list = $(".grid ul.item-list");

        if (result.length > 0) {
            $(".grid ul.item-list li:first-child").remove();

            result.forEach((item, i) => {
                let additionnalClass = (i < 6) ? 'selected' : '';
                let listItem = "";
                listItem += '<li class="cell ' + additionnalClass + '" data-id="' + item._id + '" data-type="' + item._type + '">';
                listItem += '<div class="thumbnail"><img src="' + item.imageUrl1 + '?w=400"/></div>';
                listItem += '<div class="info">';
                listItem += "<h3>" + item.title + "</h3>";
                listItem += '<p class="excerpt">' + item.excerpt + "</p>";
                listItem += "</div>";
                listItem += '<a class="grid-button">VOIR PLUS</a>';
                listItem += "</li>";

                list.append(listItem);
            });

            $(".grid .cell").on("click", function() {
                const id = $(this)[0].dataset.id;
                loadItem(id);
            });
            $(".modal-close").on("click", function() {
                $("#modal-container").addClass("out");
                $("body").removeClass("modal-active");
            });
            $(".modal-left").on("click", function() {
                browseItem(-1)
            });
            $(".modal-right").on("click", function() {
                browseItem(1)
            });

        }
    }).catch((err) => console.error(err));

}


function buildSectionQui() {
    const QUERY = encodeURIComponent(`
        *[_type == "section-qui" ]{
            _id,
            title,
            body,
            "imageUrl": image.asset->url,
          } 
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        if (result.length > 0) {
            const el = blocksToHtml({
                blocks: result[0].body,
                serializers: SERIALIZERS
            });
            $("#section-qui .content").html(el);
            if (result[0].imageUrl !== null)
                $("#section-qui .img").html("<img src='" + result[0].imageUrl + "' class='img-responsive'/>");
            $("#section-qui h1").html(result[0].title);
        }
    }).catch((err) => console.error(err));

}

function buildSectionIntro() {
    const QUERY = encodeURIComponent(`
        *[_type == "section-intro" ]{
            _id,
            body
          } 
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        if (result.length > 0) {
            const el = blocksToHtml({
                blocks: result[0].body,
                serializers: SERIALIZERS
            });
            $(".intro-text").html(el);
        }
    }).catch((err) => console.error(err));

}

async function loadItem(id) {
    const QUERY = encodeURIComponent(`
    *[(_type == "spectacle" || _type == "sonore" || _type == "ecriture" || _type == "atelier" || _type =="divers") && _id == "${id}"]{
            _id,
            title,
            order,
            body,
            attachmentText,
            "attachmentURL": attachment.asset->url,
            "imageUrl1": image1.asset->url,
            "imageUrl2": image2.asset->url,
            "imageUrl3": image3.asset->url
          }
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        if (result.length > 0) {

            openModal(result[0], SERIALIZERS);

        }
    }).catch((err) => {
        console.error(err)
    });
}

// UNUSED
function browseItem(direction) {

    const QUERY = encodeURIComponent(`
    *[_type == "spectacle" || _type == "sonore" ||_type == "ecriture" ||_type == "atelier"  ]{
            _id,
            title,
            order,
            price,
            body,
            "imageUrl1": image1.asset->url,
            "imageUrl2": image2.asset->url,
            "imageUrl3": image3.asset->url
          }  | order(order asc)
        `);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    fetch(URL).then((res) => res.json()).then(({ result }) => {
        if (result.length > 0) {

            const curid = $("#modal-container")[0].dataset.productid;
            const curind = result.findIndex(x => x._id === curid);

            if (result[curind + direction])
                loadItem(result[curind + direction]._id)
            else {
                if (curind === 0)
                    loadItem(result[result.length - 1]._id)
                else
                    loadItem(result[0]._id)
            }

        }
    }).catch((err) => console.error(err));

}

function filterItems(type) {
    $(".grid ul.item-list li[data-type='" + type + "']").addClass('selected');
    $(".grid ul.item-list li[data-type!='" + type + "']").removeClass('selected');
}

function openModal(data, serializers) {
    $(".modal h2").html(data.title);
    const el = blocksToHtml({
        blocks: data.body,
        serializers: serializers
    });
    $(".modal p#content").html(el);
    $(".modal .attachment").html(data.attachmentText).attr("href", data.attachmentURL + "?dl=");

    let imgs = [data.imageUrl1, data.imageUrl2, data.imageUrl3];
    $(".modal .images").html("");
    imgs.forEach((item) => {
        if (item)
            $(".modal .images").append('<a class href="' + item + '" target="_blank"><img src="' + item + '?w=500"/></a>');
    });
    $(".modal .images").addClass("slider");

    $("#modal-container").attr("data-productid", data._id);
    $("#modal-container").removeAttr("class").addClass("isActive");
    $("body").addClass("modal-active");
}

function updateMenuSelector(section) {
    const SELECTOR_PADDING = 20;
    let current = $('[href="#' + section.attr("id") + '"]');
    let itemHeight = parseInt(current.css("width")) + SELECTOR_PADDING;
    let itemPosition = current.position();
    $(".nav-selector").css("height", itemHeight + "px");
    $(".nav-selector").css(
        "top",
        itemPosition.top - SELECTOR_PADDING / 2
    );
}

function selectCat(cat) {
    $(".filters ul li").removeClass("selected");
    if (!$(cat).hasClass("selected"))
        $(cat).addClass("selected")
    filterItems($(cat).attr("id"))
}

function offsetAnchor() {
    if (location.hash.length !== 0) {
        window.scrollTo(window.scrollX, window.scrollY + 50);
    }
}


//////////////////////////////////////////////////////////////////////////////////
// FORMULAIRE MAIL
function checkEmpty(selector) {
    if (selector.val() == "" || selector.val() == selector.prop("placeholder")) {
        selector.addClass('formFieldError', 500);
        return false;
    } else {
        selector.removeClass('formFieldError', 500);
        return true;
    }
}

function validateEmail(email) {
    var regex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z0-9.-]{2,4}$/;
    if (!regex.test(email.val())) {
        email.addClass('formFieldError', 500);
        return false;
    } else {
        email.removeClass('formFieldError', 500);
        return true;
    }
}